import 'ui/dist/admin/styles.css';

import ForgotPassword from 'containers/login/forgot-password';
import ResendConfirmation from 'containers/login/resend-confirmation';
import SignInForm from 'containers/login/sign-in-form';
import Success from 'containers/login/success';
import VerifyAccount from 'containers/login/verify-account';
import { withTranslation } from 'i18n';
import Head from 'next/head';
import { TFunction, WithTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SiteOnBoardingImage from 'static/assets/images/admin-login-image.png';
import Logo from 'static/assets/images/granicus-logo.png';
import { User } from 'types/user';
import Button from 'ui/dist/admin/button';

interface IProps extends WithTranslation {
  user: User;
}

const SignIn: React.FC<IProps> = ({ user }) => {
  const { t } = useTranslation();

  const [view, setActiveView] = useState<
    | 'form'
    | 'forgot_password'
    | 'resend_confirmation'
    | 'verify_account'
    | 'success'
  >('form');

  const getHelperText = (view): TFunction => {
    switch (view) {
      case 'form':
      case 'forgot_password':
      case 'resend_confirmation':
      case 'success':
        return t(`login:login.${view}Heading`);
      case 'verify_account':
        return t(`login:login.${view}Heading`, {
          length: 4,
          email: user.email,
        });
      default:
        break;
    }
  };

  return (
    <>
      <Head>
        <title>{t('login:login.adminLogin')}</title>
      </Head>
      <div className="ehq-flex ehq-h-screen ehq-flex-wrap">
        <div className="ehq-w-full md:ehq-w-full lg:ehq-w-3/6 ehq-py-6 ehq-flex ehq-items-center ehq-justify-center">
          <div className="container ehq-w-full">
            <div className="ehq-w-full md:ehq-w-4/5 lg:ehq-w-full ehq-m-auto ehq-text-center ehq-space-y-10">
              <img
                alt={t('login:login.logoAlt')}
                src={Logo}
                width="290px"
              />
              <h1 className="ehq-font-bold ehq-tracking-tight sm:ehq-text-4xl md:ehq-text-4xl lg:ehq-text-4xl xl:ehq-text-4xl">
                {getHelperText(view)}
              </h1>

              {view !== 'form' && (
                <div className="ehq-text-left ehq-mx-auto ehq-w-full md:ehq-w-5/6 lg:ehq-w-5/12">
                  <p className="ehq-text-sm ehq-my-10 help-text">
                    {t(`login:login.${view}HelpText`)}
                  </p>
                </div>
              )}
              {view === 'form' ? (
                <>
                  <SignInForm setActiveView={setActiveView} />
                  <div className="ehq-text-center ehq-my-12">
                    <Button
                      type="link"
                      className="ehq-p-0"
                      onClick={(): void => {
                        setActiveView('forgot_password');
                      }}
                    >
                      {t('login:login.forgotPassword')}
                    </Button>
                  </div>
                </>
              ) : view === 'forgot_password' ? (
                <ForgotPassword />
              ) : view === 'verify_account' ? (
                <VerifyAccount setActiveView={setActiveView} />
              ) : view === 'success' ? (
                <Success />
              ) : (
                <ResendConfirmation setActiveView={setActiveView} />
              )}
            </div>
          </div>
        </div>
        <div className="ehq-w-0 lg:ehq-w-3/6 ehq-flex ehq-items-center ehq-justify-center image-background">
          <img
            alt={t('site-on-board:siteOnBoard.logoAlt')}
            src={SiteOnBoardingImage}
            width="450"
            height="518"
          />
        </div>
        <style jsx>{`
          :global(.ant-form-item-label) {
            line-height: 30px;
          }
          :global(.has-error .ant-input) {
            border-width: 2px;
          }
          :global(label.ant-form-item-required:after) {
            display: none;
          }
          .image-background {
            background-color: #ecf0f7;
          }
          .container {
            height: 600px;
          }
          .help-text {
            color: #595959;
          }
        `}</style>
      </div>
    </>
  );
};

export default withTranslation(['login', 'site-on-board'])(SignIn);
